/* Sentry */
import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, enableProdMode, ErrorHandler, LOCALE_ID, NgModule} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";
import {Integrations} from "@sentry/tracing";

import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {Router} from "@angular/router";
import {MAT_DATE_LOCALE} from '@angular/material/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

/* UI Libraries */
import {MaterialModule} from './_commons/material.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

/* Plugins */
//  notifiche
import {SimpleNotificationsModule} from 'angular2-notifications';
import {ToastNotificationComponent} from './utility/toast-notification/toast-notification.component';
//  grafici
import {GoogleChartsModule} from 'angular-google-charts';
import { NgxEchartsModule } from 'ngx-echarts';
//  alerts
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
/* file viewer */
import { NgxDocViewerModule } from 'ngx-doc-viewer';

/* Localizzazione */
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localeIT from '@angular/common/locales/it';
/* Providers */
import {HttpXsrfInterceptorInterceptor} from './_interceptors/http-xsrf-interceptor.interceptor';
import {BackendService} from './services/general/backend.service';

/* Componenti Helper */
import {Scroll} from './_helpers/scroll/scroll.component';

/* Direttive */
import {TrimValueAccessorDirective} from './_directives/trim-value-accessor.directive';
import {BackbuttonDirective} from './_directives/back-button.directive';

/* Pipes */
import {FormatdatePipe} from './_pipes/formatdate.pipe';
import {MinuteSecondsPipe} from './_pipes/minute-seconds.pipe';

/* Componenti principali */
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {BootstrapComponent} from './pages/bootstrap/bootstrap.component';

/* Modali */
import {ModalAddAttachmentComponent} from './modals/modal-add-attachment/modal-add-attachment.component';
import {ModalUploadFrameworkComponent} from './modals/modal-upload-framework/modal-upload-framework.component';
import {ModalNewProposalComponent} from './modals/modal-new-proposal/modal-new-proposal.component';
import {ModalReportAttachmentComponent} from './modals/modal-report-attachment/modal-report-attachment.component';
import {ModalPrivacyPolicyComponent} from './modals/modal-privacy-policy/modal-privacy-policy.component';
import {ModalShowEvidencesComponent} from './modals/modal-show-evidences/modal-show-evidences.component';
import {ModalUserPermissionComponent} from './modals/modal-user-permissions/modal-user-permissions.component';
import {ModalYourOrganizationsComponent} from './modals/modal-your-organizations/modal-your-organizations.component';
import {ModalAddWorkExpComponent} from './modals/modal-add-work-exp/modal-add-work-exp.component';
import {ModalAddEducationComponent} from './modals/modal-add-education/modal-add-education.component';
import {ModalAddCertificateComponent} from './modals/modal-add-certificate/modal-add-certificate.component';
import {ModalProposalRenameComponent} from './modals/modal-proposal-rename/modal-proposal-rename.component';
import { ModalAddContestComponent } from './modals/modal-add-contest/modal-add-contest.component';
import { ModalShowContestComponent } from './modals/modal-show-contest/modal-show-contest.component';
import { ModalShowProposalsComponent } from './modals/modal-show-proposals/modal-show-proposals.component';
import { ModalTemplateEmailComponent } from './modals/modal-template-email/modal-template-email.component';
import { ModalEmailResponsesComponent } from './modals/modal-email-responses/modal-email-responses.component';

/* Allegati */
import {NgxUploaderModule} from 'ngx-uploader';
import {PubmedAttachmentComponent} from './components/attachments/pubmed-attachment/pubmed-attachment.component';
import {FileAttachmentComponent} from './components/attachments/file-attachment/file-attachment.component';
// import { ViewerComponent } from "./pages/fileviewer/viewer.component";
import { FileViewerComponent } from "./components/fileviewer/file-viewer.component";

/* Componenti pagine */
import {HomeComponent} from './pages/home/home.component';
import {AllFrameworksComponent} from './pages/frameworks/all-frameworks/all-frameworks.component';
import {FrameworkComponent} from './pages/frameworks/framework/framework.component';
import {ResponsesComponent} from './pages/responses/responses.component';


import {
  NewDecisionTableSelectFrameworkComponent
} from './components/decision-table/new-decision-table/new-decision-table-select-framework/new-decision-table-select-framework.component';
import {
  NewDecisionTableCompileProposalComponent
} from './components/decision-table/new-decision-table/new-decision-table-compile-proposal/new-decision-table-compile-proposal.component';
import {
  NewDecisionTableCompileProblemStatementComponent
} from './components/decision-table/new-decision-table/new-decision-table-compile-problem-statement/new-decision-table-compile-problem-statement.component';
import {
  DecisionTableClosingComponent
} from './pages/decision-tables/decision-table-closing/decision-table-closing.component';
import {
  ShowDecisionTableComponent
} from './components/decision-table/show-decision-table/show-decision-table.component';

import {NewProposalComponent} from './components/proposals/new-proposal/new-proposal.component';
import {NewProposalBlankComponent} from './components/proposals/new-proposal-blank/new-proposal-blank.component';
import {
  MultiobjectiveNewProposalComponent
} from "./components/proposals/new-proposal/multiobjective/multiobjective-new-proposal.component";
import {
  MulticriteriaNewProposalComponent
} from "./components/proposals/new-proposal/multicriteria/multicriteria-new-proposal.component";
import {
  ConsensusNewProposalComponent
} from "./components/proposals/new-proposal/consensus/consensus-new-proposal.component";

import {ResponsesWelcomeComponent} from './pages/responses/responses-welcome/responses-welcome.component';
import {ResponsesSummaryComponent} from './pages/responses/responses-summary/responses-summary.component';
import {
  ResponsesWeightTargetsComponent
} from './pages/responses/responses-weight-targets/responses-weight-targets.component';
import {
  ResponsesWeightCriteriaComponent
} from './pages/responses/responses-weight-criteria/responses-weight-criteria.component';
import {ResponsesEvaluationComponent} from './pages/responses/responses-evaluation/responses-evaluation.component';
import {
  ResponsesProposalReviewComponent
} from './pages/responses/responses-proposal-review/responses-proposal-review.component';
import {
  ResponsesSuggestNewProposalComponent
} from './components/responses/responses-suggest-new-proposal/responses-suggest-new-proposal.component';
import {ResponsesThankYouComponent} from './pages/responses/responses-thank-you/responses-thank-you.component';
import {ResponsesExplainComponent} from './pages/responses/responses-explain/responses-explain.component';

import {
  MultiobjectiveResponsesSummaryComponent
} from './components/responses/multiobjective/multiobjective-responses-summary/multiobjective-responses-summary.component';
import {
  MultiobjectiveResponsesWeightTargetsComponent
} from './components/responses/multiobjective/multiobjective-responses-weight-targets/multiobjective-responses-weight-targets.component';
import {
  MultiobjectiveResponsesWeightCriteriaComponent
} from './components/responses/multiobjective/multiobjective-responses-weight-criteria/multiobjective-responses-weight-criteria.component';
import {
  MultiobjectiveResponsesEvaluationComponent
} from './components/responses/multiobjective/multiobjective-responses-evaluation/multiobjective-responses-evaluation.component';
import {
  MultiobjectiveResponsesExplainComponent
} from './components/responses/multiobjective/multiobjective-responses-explain/multiobjective-responses-explain.component';
import {
  MulticriteriaResponsesSummaryComponent
} from './components/responses/multicriteria/multicriteria-responses-summary/multicriteria-responses-summary.component';
import {
  MulticriteriaResponsesWeightCriteriaComponent
} from './components/responses/multicriteria/multicriteria-responses-weight-criteria/multicriteria-responses-weight-criteria.component';
import {
  MulticriteriaResponsesEvaluationComponent
} from './components/responses/multicriteria/multicriteria-responses-evaluation/multicriteria-responses-evaluation.component';
import {
  MulticriteriaResponsesExplainComponent
} from './components/responses/multicriteria/multicriteria-responses-explain/multicriteria-responses-explain.component';
import {
  ConsensusResponsesSummaryComponent
} from './components/responses/consensus/consensus-responses-summary/consensus-responses-summary.component';
import {
  ConsensusResponsesEvaluationComponent
} from './components/responses/consensus/consensus-responses-evaluation/consensus-responses-evaluation.component';
import {
  ConsensusResponsesExplainComponent
} from './components/responses/consensus/consensus-responses-explain/consensus-responses-explain.component';

import {DecisionTableComponent} from './pages/decision-tables/decision-table/decision-table.component';
import {
  DecisionTableDashboardComponent
} from './pages/decision-tables/decision-table-dashboard/decision-table-dashboard.component';
import {
  ConsensusDecisionTableDashboardComponent
} from "./components/decision-table/dashboard/consensus-decision-table-dashboard/consensus-decision-table-dashboard.component";
import {
  MultiobjectiveDecisionTableDashboardComponent
} from "./components/decision-table/dashboard/multiobjective-decision-table-dashboard/multiobjective-decision-table-dashboard.component";
import {
  MulticriteriaDecisionTableDashboardComponent
} from "./components/decision-table/dashboard/multicriteria-decision-table-dashboard/multicriteria-decision-table-dashboard.component";
import {AllDecisionTableComponent} from './pages/decision-tables/all-decision-table/all-decision-table.component';

import {environment} from 'src/environments/environment';
import {TaskManagementComponent} from './pages/task-management/task-management/task-management.component';
import {
  DecisionManagementPlanComponent
} from './pages/decision-management-plan/decision-management-plan/decision-management-plan.component';

import {OrganizationsComponent} from './pages/organizations/organizations.component';
import {OrganizationComponent} from './pages/organizations/organization/organization.component';
import {
  OrganizationManagementComponent
} from './pages/organizations/organization-management/organization-management.component';
import {
  OrganizationAddRoleComponent
} from './pages/organizations/organization-add-role/organization-add-role.component';
import {DefinitionRolesComponent} from './pages/definition-roles/definition-roles.component';
import {OrganizationalFunctionsComponent} from './pages/organizational-functions/organizational-functions.component';

import {AllUsersManagementComponent} from './pages/user/all-users-management/all-users-management.component';
import {AllDocumentsManagementComponent} from './pages/organizations/all-documents-management/all-documents-management.component';
import {TemplateMailManagementComponent} from './pages/organizations/template-mail-management/template-mail-management.component';
import {SingleUserManagementComponent} from './pages/user/single-user-management/single-user-management.component';
import {UserProfileComponent} from './pages/user/user-profile/user-profile.component';
import {CurriculumComponent} from './pages/user/curriculum/curriculum.component';
import {ErrorPageComponent} from './error-page/error-page.component';
import {RegisterComponent} from './register/register.component';
import {LoginComponent} from './pages/login/login.component';
import { GuestComponent } from './pages/guest/guest.component';

import {OnboardingComponent} from './pages/onboarding/onboarding.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { ChangePasswordComponent } from './pages/user/change-password/change-password.component';
import { NewContestProposalComponentComponent } from './components/contests/new-contest-proposal-component/new-contest-proposal-component.component';
import { ShowContestComponentComponent } from './components/contests/show-contest-component/show-contest-component.component';
import { OrganizationChartsManagementComponent } from './pages/organizations/organization-charts-management/organization-charts-management.component';
import { ModalOrganizationChartComponent } from './modals/modal-organization-chart/modal-organization-chart.component';
import { ModalImportOrganizationChartsComponent } from './modals/modal-import-organization-charts/modal-import-organization-charts.component';

import { ModalResetPasswordComponent } from './modals/modal-reset-password/modal-reset-password.component';
import { ModalChangePasswordComponent } from './modals/modal-change-password/modal-change-password.component';
import { ModalMagicWriterComponent } from './modals/modal-magic-writer/modal-magic-writer.component';
import { ModalDashboardAiExplanationComponent } from './modals/modal-dashboard-ai-explanation/modal-dashboard-ai-explanation.component';
import { SingleTeamMemberComponent } from "./components/single-team-member/single-team-member.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ModalEditWeightsComponent } from './modals/modal-edit-weights/modal-edit-weights.component';
import { CriteriaTargetsWeightUpdateComponent } from './components/framework/criteria-targets-weight-update/criteria-targets-weight-update.component';
import { ProjectsComponent } from "./pages/projects/all-projects/projects.component";
import { ProjectDetailComponent } from './pages/projects/project-detail/project-detail.component';
import { ModalAddProjectComponent } from './modals/modal-add-project/modal-add-project.component';
import { AllSurveysComponent } from './components/surveys/all-surveys/all-surveys.component';
import { SurveyDetailsComponent } from './components/surveys/survey-details/survey-details.component';
import { SingleQuestionComponent } from './components/surveys/single-question/single-question.component';
import { ModalSurveyDetailsComponent } from './modals/modal-survey-details/modal-survey-details.component';
import { SurveyDetailsPageComponent } from './pages/surveys/survey-details-page/survey-details-page.component';
import { TeamMembersListComponent } from './components/surveys/team-members-list/team-members-list.component';
import {MatTabsModule} from "@angular/material/tabs";
import { SurveyTeamComponent } from './components/surveys/survey-team/survey-team.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { SurveyResponsePageComponent } from './pages/surveys/survey-response-page/survey-response-page.component';
import {AttachmentsListComponent} from "./components/surveys/attachments-list/attachments-list.component";
import { SurveyAllResponsesComponent } from './components/surveys/survey-all-responses/survey-all-responses.component';
import { SingleQuestionResponsesComponent } from './components/surveys/single-question-responses/single-question-responses.component';
import { ShowIfOrganizationDirective } from './_directives/show-if-organization.directive';

import { LoadingComponent } from './components/loading/loading.component';
import { GenericLoadingComponent } from './components/genericloading/generic-loading.component';

import {DragDropModule} from "@angular/cdk/drag-drop";
import { DecisionTableDetailsComponent } from './components/decision-table/decision-table-details/decision-table-details.component';
import { HideIfOrganizationDirective } from './_directives/hide-if-organization.directive';
import { ModalAddInvitationPageComponent } from './modals/modal-add-invitation-page/modal-add-invitation-page.component';
import {RichTextEditorComponent} from 'src/app/components/rich-text-editor/rich-text-editor.component';
import { FormattedTextPreviewComponent } from 'src/app/components/rich-text-editor/formatted-text-preview.component';
import { DecisionJoinRequestComponent } from './pages/decision-join-request/decision-join-request.component';
import { ModalSelectProcessRoleComponent } from './modals/modal-select-process-role/modal-select-process-role.component';

import {QrCodeComponent} from './components/qrcode/qrcode.component';
import { ModalAddTextComponent } from './modals/modal-add-text/modal-add-text.component';
import {MarkdownModule} from "ngx-markdown";
import { ShowIfOrganizationHasFeatureDirective } from './_directives/show-if-organization-has-feature.directive';
import { SingleQuestionAiAnalysisComponent } from './components/ai-analysis/single-question-ai-analysis/single-question-ai-analysis.component';
import { ModalShowSurveysComponent } from './modals/modal-show-surveys/modal-show-surveys.component';
import { ModalInvitationPagesSettingsComponent } from './modals/modal-invitation-pages-settings/modal-invitation-pages-settings.component';

// import localeITExtra from '@angular/common/locales/extra/it';
// registerLocaleData(localeIT, localeITExtra);
registerLocaleData(localeIT);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http, '/assets/i18n/', '.json?cache=' + environment.version);
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + environment.version);
  // return new TranslateHttpLoader(http);
}


@NgModule({
    declarations: [
        AppComponent,
        ToastNotificationComponent,
        TrimValueAccessorDirective,
        BackbuttonDirective,
        Scroll,
        FormatdatePipe,
        MinuteSecondsPipe,

        BootstrapComponent,

        HeaderComponent,
        FooterComponent,
        HomeComponent,
        ProjectsComponent,

        AllFrameworksComponent,
        FrameworkComponent,

        DecisionTableComponent,
        AllDecisionTableComponent,
        NewDecisionTableSelectFrameworkComponent,
        NewDecisionTableCompileProposalComponent,
        NewDecisionTableCompileProblemStatementComponent,
        ShowDecisionTableComponent,
        DecisionTableDashboardComponent,
        ConsensusDecisionTableDashboardComponent,
        MultiobjectiveDecisionTableDashboardComponent,
        MulticriteriaDecisionTableDashboardComponent,

        ModalNewProposalComponent,
        NewProposalComponent,
        NewProposalBlankComponent,
        MultiobjectiveNewProposalComponent,
        MulticriteriaNewProposalComponent,
        ConsensusNewProposalComponent,

        ModalAddAttachmentComponent,
        ModalUploadFrameworkComponent,
        ModalAddWorkExpComponent,
        ModalAddEducationComponent,
        ModalAddCertificateComponent,
        ModalProposalRenameComponent,
        ModalShowEvidencesComponent,
        ModalUserPermissionComponent,
        ModalReportAttachmentComponent,
        ModalPrivacyPolicyComponent,
        ModalYourOrganizationsComponent,
        ModalAddContestComponent,
        ModalShowContestComponent,
        ModalShowProposalsComponent,
        ModalTemplateEmailComponent,
        ModalEmailResponsesComponent,

        ResponsesComponent,
        ResponsesWelcomeComponent,
        ResponsesSummaryComponent,
        ResponsesWeightTargetsComponent,
        ResponsesWeightCriteriaComponent,
        ResponsesEvaluationComponent,
        ResponsesProposalReviewComponent,
        ResponsesSuggestNewProposalComponent,
        ResponsesThankYouComponent,
        ResponsesExplainComponent,

        MultiobjectiveResponsesSummaryComponent,
        MultiobjectiveResponsesWeightTargetsComponent,
        MultiobjectiveResponsesWeightCriteriaComponent,
        MultiobjectiveResponsesEvaluationComponent,
        MultiobjectiveResponsesExplainComponent,
        MulticriteriaResponsesEvaluationComponent,
        MulticriteriaResponsesSummaryComponent,
        MulticriteriaResponsesWeightCriteriaComponent,
        MulticriteriaResponsesExplainComponent,
        ConsensusResponsesSummaryComponent,
        ConsensusResponsesEvaluationComponent,
        ConsensusResponsesExplainComponent,

        TaskManagementComponent,
        DecisionManagementPlanComponent,
        FormatdatePipe,

        PubmedAttachmentComponent,
        FileAttachmentComponent,

        // ViewerComponent,
        FileViewerComponent,

        OrganizationsComponent,
        OrganizationComponent,
        OrganizationAddRoleComponent,
        OrganizationManagementComponent,

        DefinitionRolesComponent,
        OrganizationalFunctionsComponent,

        AllUsersManagementComponent,
        AllDocumentsManagementComponent,
        TemplateMailManagementComponent,
        SingleUserManagementComponent,

        UserProfileComponent,
        CurriculumComponent,
        ErrorPageComponent,
        RegisterComponent,
        LoginComponent,
        DecisionTableClosingComponent,
        OnboardingComponent,
        ChangePasswordComponent,
        NewContestProposalComponentComponent,
        ShowContestComponentComponent,
        OrganizationChartsManagementComponent,
        ModalOrganizationChartComponent,
        ModalImportOrganizationChartsComponent,
        ModalResetPasswordComponent,
        ModalChangePasswordComponent,
        SingleTeamMemberComponent,
        ModalEditWeightsComponent,
        CriteriaTargetsWeightUpdateComponent,
        GuestComponent,
        ProjectDetailComponent,
        ModalAddProjectComponent,
        AllSurveysComponent,
        SurveyDetailsComponent,
        SingleQuestionComponent,
        ModalSurveyDetailsComponent,
        SurveyDetailsPageComponent,
        TeamMembersListComponent,
        SurveyTeamComponent,
        SurveyResponsePageComponent,
        AttachmentsListComponent,
        SurveyAllResponsesComponent,
        SingleQuestionResponsesComponent,
        ShowIfOrganizationDirective,
        LoadingComponent,
        GenericLoadingComponent,
        ModalMagicWriterComponent,
        ModalDashboardAiExplanationComponent,
        DecisionTableDetailsComponent,
        HideIfOrganizationDirective,
        ModalAddInvitationPageComponent,
        RichTextEditorComponent,
        FormattedTextPreviewComponent,
        DecisionJoinRequestComponent,
        ModalSelectProcessRoleComponent,
        QrCodeComponent,
        ModalAddTextComponent,
        ShowIfOrganizationHasFeatureDirective,
        SingleQuestionAiAnalysisComponent,
        ModalShowSurveysComponent,
        ModalInvitationPagesSettingsComponent
    ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ScrollingModule,
    NgxDocViewerModule,
    SimpleNotificationsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'it',
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbModule,
    NgxUploaderModule,
    SweetAlert2Module.forRoot(),
    GoogleChartsModule,
    FontAwesomeModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    DragDropModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    MarkdownModule.forRoot(),
  ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({showDialog: true}),
        },
        {provide: Sentry.TraceService, deps: [Router]},
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {
            },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpXsrfInterceptorInterceptor,
            multi: true,
        },
        {provide: LOCALE_ID, useValue: 'it-IT'},
        {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
        BackendService,
    ],
    bootstrap: [AppComponent],
    exports: [
        SurveyDetailsComponent
    ]
})
export class AppModule {}



/* Abilitato Sentry per il testing */
if (environment.production == true && environment.testing == true) {
  Sentry.init({
    dsn: "https://3841a832b830407890d4dbc77e3425ee@o560899.ingest.sentry.io/5696912",
    release: environment.version,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", "https://testing.butterflydecisions.com"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  enableProdMode();
  platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));
}

/* Abilitato Sentry per la produzione */
if (environment.production == true && environment.testing == false) {
  Sentry.init({
    dsn: "https://a24e28532b8744a5b7cc32f2a903d17d@o560899.ingest.sentry.io/6049620",
    release: environment.version,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", "https://app.butterflydecisions.com"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  enableProdMode();
  platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));
}
