<div class="container bg-header">
  <div class="row">
    <div class="col-8">
      <h1 mat-dialog-title class="text-white p-3 m-0">
        {{'modal.modal-virtual-assistant.title' | translate}}
      </h1>
    </div>
    <div class="col-4 d-flex justify-content-end align-items-center">
      <p role="button" (click)="closeDialogue()" class="text-white m-0">{{'generic.cancel' | translate}}</p>
      <mat-icon class="ml-1" (click)="closeDialogue()">cancel</mat-icon>
    </div>
  </div>
</div>
<div mat-dialog-content class="m-3">
  <h3>{{ this.criterio.value }}</h3>
  <form #form="ngForm" (keydown.enter)="$event.preventDefault()">

    <mat-card class="box mt-3 py-4">
      <mat-form-field class="col-12">
        <mat-label>{{'generic.typology'| translate}}</mat-label>
<!--        <mat-select name="propmt_list" [(ngModel)]="organizationInfo.typology" required class="color-default">-->
        <mat-select name="propmt_list" required class="color-default"  [(ngModel)]="selectedPrompt" (selectionChange)="onSelectionChange($event)" value="proposeRevision">
<!--    <mat-option *ngFor="let typology of getTypologiesService().getAllTypologiesForSelect()" [value]="typology.key">{{typology.value}}</mat-option>-->
          <mat-option [value]="proposeRevision"> Propose revision</mat-option>
          <mat-option [value]="rewriteStatement"> Rewrite statement </mat-option>
          <mat-option [value]="rewriteStatementBeforeAnswers"> Rewrite statement before answers </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Non ci sarà tabella delle risposte per elementi quantitativi -->
                    <div
                      class="row"
                      *ngIf="
                        criterio.response &&
                        getCriteriaService().criteriaIsQualitative(
                          criterio.typology
                        )
                      "
                    >
                      <!-- Tabella Feedback-->
                      <div
                        class="col-12 px-2"
                        *ngIf="criterio.response.length > 0"
                      >
                        <mat-table
                          [dataSource]="criterio.response"
                          class="table-primary"
                        >
                          <!-- Name Column -->
<!--                          <ng-container matColumnDef="name">-->
<!--                            <mat-header-cell *matHeaderCellDef>{{-->
<!--                              "generic.name" | translate-->
<!--                              }}</mat-header-cell>-->
<!--                            <mat-cell *matCellDef="let element; let i = index">-->
<!--                              <span-->
<!--                                *ngIf="element?.team_member?.name && showPrivateInfo; else prova">{{ element?.team_member?.name }}</span>-->
<!--                              <ng-template #prova>-->
<!--                                <span>{{ "generic.user" | translate }} {{ i + 1 }}</span>-->
<!--                              </ng-template>-->


<!--                            </mat-cell>-->
<!--                          </ng-container>-->

                          <!-- Sentiment Column -->
                          <ng-container matColumnDef="sentiment">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >{{ "generic.sentiment" | translate }}
                            </mat-header-cell>
                            <mat-cell
                              *matCellDef="let element"
                              style="justify-content: center"
                            >
                              <mat-icon
                                aria-hidden="false"
                                *ngIf="element.sentiment_score == 0"
                              >sentiment_neutral
                              </mat-icon
                              >
                              <mat-icon
                                aria-hidden="false"
                                style="color: #00bc1d"
                                *ngIf="element.sentiment_score > 0"
                              >sentiment_satisfied_alt
                              </mat-icon
                              >
                              <mat-icon
                                aria-hidden="false"
                                style="color: #ff0000"
                                *ngIf="element.sentiment_score < 0"
                              >sentiment_very_dissatisfied
                              </mat-icon
                              >
                            </mat-cell>
                          </ng-container>

                          <!-- Feedback Column -->
                          <ng-container matColumnDef="feedback">
                            <!-- <mat-header-cell *matHeaderCellDef style="flex: 0 0 50% !important">Feedback </mat-header-cell>
                                              <mat-cell *matCellDef="let element" style="flex: 0 0 50% !important"> {{element.feedback}} </mat-cell> -->
                            <mat-header-cell
                              *matHeaderCellDef
                              style="flex: 0 0 50% !important"
                            >{{
                              "generic.feedback" | translate
                              }}</mat-header-cell
                            >
                            <mat-cell
                              *matCellDef="let element"
                              style="flex: 0 0 50% !important"
                            >
                              {{ element.feedback }}
                            </mat-cell>
                          </ng-container>

                          <!-- True False Column -->
                          <ng-container matColumnDef="truefalse">
                            <mat-header-cell *matHeaderCellDef>{{
                              "components.proposals.new-proposal.true-false"
                                | translate
                              }}</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                              <mat-chip-list>
                                <mat-chip
                                  selectable="false"
                                  *ngIf="
                                    getResponsesTypeService().isConsensusAgree(
                                      element.consent_value
                                    )
                                  "
                                  style="
                                    justify-content: center;
                                    pointer-events: none;
                                    background-color: #00bc1d;
                                  "
                                  class="text-white"
                                >{{
                                  "generic.answers.true" | translate
                                  }}</mat-chip
                                >
                                <mat-chip
                                  selectable="false"
                                  *ngIf="
                                    getResponsesTypeService().isConsensusDisagree(
                                      element.consent_value
                                    )
                                  "
                                  style="
                                    justify-content: center;
                                    pointer-events: none;
                                    background-color: #ff0000;
                                  "
                                  class="text-white"
                                >{{
                                  "generic.answers.false" | translate
                                  }}</mat-chip
                                >
                                <mat-chip
                                  selectable="false"
                                  *ngIf="
                                    getResponsesTypeService().isConsensusAbstained(
                                      element.consent_value
                                    )
                                  "
                                  style="
                                    justify-content: center;
                                    pointer-events: none;
                                    background-color: rgb(167, 167, 167);
                                  "
                                  class="text-white"
                                >{{
                                  "generic.answers.abstained" | translate
                                  }}</mat-chip
                                >
                              </mat-chip-list>
                            </mat-cell>
                          </ng-container>

                          <!-- Confidence Column -->
                          <ng-container matColumnDef="confidence">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >{{
                              "components.proposals.new-proposal.confidence"
                                | translate
                              }}</mat-header-cell
                            >
                            <mat-cell
                              *matCellDef="let element"
                              style="justify-content: center"
                            >
                              <mat-chip-list
                              >
                                <mat-chip>{{
                                  element.confidence
                                  }}</mat-chip>
                              </mat-chip-list
                              >
                            </mat-cell>
                          </ng-container>

                          <!-- evidence Column -->
<!--                          <ng-container matColumnDef="evidence">-->
<!--                            <mat-header-cell-->
<!--                              *matHeaderCellDef-->
<!--                              style="justify-content: center"-->
<!--                            >{{-->
<!--                              "components.proposals.new-proposal.evidences"-->
<!--                                | translate-->
<!--                              }}</mat-header-cell-->
<!--                            >-->
<!--                            <mat-cell-->
<!--                              *matCellDef="let element"-->
<!--                              style="justify-content: center"-->
<!--                              (click)="element?.attachment_expert_pubmed.length + element?.attachment_expert_files.length > 0 ? showExpertEvideces(element) : ''"-->
<!--                            >-->
<!--                              <mat-chip-list-->
<!--                              >-->
<!--                                <mat-chip>-->
<!--                                  {{-->
<!--                                  element?.attachment_expert_pubmed.length +-->
<!--                                  element?.attachment_expert_files.length-->
<!--                                  }}</mat-chip>-->
<!--                              </mat-chip-list-->
<!--                              >-->
<!--                            </mat-cell>-->

<!--                          </ng-container>-->

                          <!-- allegati aperti Column -->
<!--                          <ng-container matColumnDef="attachment_events">-->
<!--                            <mat-header-cell-->
<!--                              *matHeaderCellDef-->
<!--                              style="justify-content: center"-->
<!--                            >{{-->
<!--                              "components.proposals.new-proposal.attachment-opened"-->
<!--                                | translate-->
<!--                              }}</mat-header-cell-->
<!--                            >-->
<!--                            <mat-cell-->
<!--                              *matCellDef="let element"-->
<!--                              style="justify-content: center"-->
<!--                            >-->
<!--                              <mat-chip-list-->
<!--                              >-->
<!--                                <mat-chip-->
<!--                                  style="cursor: pointer"-->
<!--                                  *ngIf="element.attachment_events"-->
<!--                                  (click)="element?.attachment_events.length > 0 ? onClickAttachmentEventList(element) : ''"-->
<!--                                >{{-->
<!--                                  element?.attachment_events.length-->
<!--                                  }}</mat-chip-->
<!--                                >-->
<!--                              </mat-chip-list-->
<!--                              >-->
<!--                            </mat-cell>-->
<!--                          </ng-container>-->

                          <!-- Confidence Column -->
                          <ng-container matColumnDef="select">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >
                                {{'modal.modal-virtual-assistant.select-feedback' | translate }}
                              </mat-header-cell
                            >
                            <mat-cell *matCellDef="let element;" style="justify-content: center">
                              <mat-chip-list>
                                <mat-chip>
                                  <input type="checkbox" [(ngModel)]="element.checked" name="feedback"/>
                                </mat-chip>
                              </mat-chip-list>
                            </mat-cell>
                          </ng-container>

                          <mat-header-row
                            *matHeaderRowDef="displayedColumns"
                          ></mat-header-row>
                          <mat-row
                            *matRowDef="let row; columns: displayedColumns"
                          ></mat-row>
                        </mat-table>
                      </div>


                      <mat-table
                          [dataSource]="criterio.attachment_pubmed"
                          class="table-primary"
                        >
                          <!-- Pubmed evidence Column -->
                          <ng-container matColumnDef="pubmed">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >{{ "components.proposals.new-proposal.evidences" | translate }}
                            </mat-header-cell>
                            <mat-cell
                              *matCellDef="let element"
                              style="justify-content: center"
                            >
                              {{ element.title }}
                            </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="url">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >{{ "components.proposals.new-proposal.evidences" | translate }}
                            </mat-header-cell>
                            <mat-cell
                              *matCellDef="let element"
                              style="justify-content: center"
                            >
                              {{ element.url }}
                            </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="select">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >
                                {{'modal.modal-virtual-assistant.select-feedback' | translate }}
                              </mat-header-cell
                            >
                            <mat-cell *matCellDef="let element;" style="justify-content: center">
                              <mat-chip-list>
                                <mat-chip>
                                  <input type="checkbox" [(ngModel)]="element.checked" name="feedback"/>
                                </mat-chip>
                              </mat-chip-list>
                            </mat-cell>
                          </ng-container>

                          <mat-header-row
                            *matHeaderRowDef="displayedColumnsPubmedAttachments"
                          ></mat-header-row>
                          <mat-row
                            *matRowDef="let row; columns: displayedColumnsPubmedAttachments"
                          ></mat-row>
                      </mat-table>

                      <mat-table
                          [dataSource]="criterio.attachment_files"
                          class="table-primary"
                        >
                          <!-- Pubmed evidence Column -->
                          <ng-container matColumnDef="file">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >{{ "components.proposals.new-proposal.evidences" | translate }}
                            </mat-header-cell>
                            <mat-cell
                              *matCellDef="let element"
                              style="justify-content: center"
                            >
                              {{ element.title }}
                            </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="url">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >{{ "components.proposals.new-proposal.evidences" | translate }}
                            </mat-header-cell>
                            <mat-cell
                              *matCellDef="let element"
                              style="justify-content: center"
                            >
                              {{ element.url }}
                            </mat-cell>
                          </ng-container>

                          <ng-container matColumnDef="select">
                            <mat-header-cell
                              *matHeaderCellDef
                              style="justify-content: center"
                            >
                                {{'modal.modal-virtual-assistant.select-feedback' | translate }}
                              </mat-header-cell
                            >
                            <mat-cell *matCellDef="let element;" style="justify-content: center">
                              <mat-chip-list>
                                <mat-chip>
                                  <input type="checkbox" [(ngModel)]="element.checked" name="feedback"/>
                                </mat-chip>
                              </mat-chip-list>
                            </mat-cell>
                          </ng-container>

                          <mat-header-row
                            *matHeaderRowDef="displayedColumnsFileAttachments"
                          ></mat-header-row>
                          <mat-row
                            *matRowDef="let row; columns: displayedColumnsFileAttachments"
                          ></mat-row>
                        </mat-table>

                    </div>

      <mat-card-actions align="end">
        <ng-container *ngIf="!loading; else elseBlock">
          <button mat-flat-button color="primary" class="action-button" (click)="onSubmit(form)">
            {{'modal.modal-virtual-assistant.generate-response' | translate}}

          </button>
          <button  mat-flat-button color="primary"  class="action-button"
                    (click)="copyText(response)"
                    [disabled]="disabledCopyButton">
                    {{'modal.modal-virtual-assistant.btn.copyandclose' | translate}}
          </button>
        </ng-container>
        <ng-template #elseBlock>
          <mat-spinner diameter="40"></mat-spinner>
        </ng-template>
      </mat-card-actions>

    </mat-card>

    <div class="text-container" *ngIf="response" [innerHTML]="renderedHTML">
<!--      {{ response }}>-->
    </div>

  </form>
</div>
